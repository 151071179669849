:root {
  --orange: #ef7d22;
  --gray: #808080;
  --bright-gray: #aaaaaa;
  --bright-gray-lighter: #d9d9d9;
  --light-gray: #262626;
  --light-gray-darker: #57575c;
  --light-gray-darkest: #181c32;
  --light-elevation-200: #37415114;
  --dark-gray: #151515;
  --darker-gray: #222222;
  --business-gray: #aeaeb2;
  --gallery: #eeeeee;
  --shark: #2a282a;
  --mulled-wine-50: #4b486780; // named by https://www.hexdictionary.com/color/4d4b68
  --primary-ine-dark-orange: #ee3b00;
  --primary-ine-gradient: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  --alert-gradient-negative: linear-gradient(270deg, #e52a2a 0%, #a21010 100%);
  --glass-mid-gray-solid: linear-gradient(89.99deg, #3e3f49b3 -13.91%, #3e3f49 97.14% 100.48%);
  --glass-mid-gray: linear-gradient(
    104.99deg,
    rgba(62, 63, 73, 0.7) -13.91%,
    rgba(62, 63, 73, 0) 100.48%
  );
  --glass-mid-gray-light: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-solid-mid-gray: linear-gradient(89.99deg, #777777 0.5%, #2e2e2e 97.14%);
  --glass-light-gray: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.7) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-extra-light: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-dark-gray: linear-gradient(
    18.34deg,
    rgba(12, 12, 13, 0.76) 46.26%,
    rgba(12, 12, 13, 0.6) 93.99%
  );
  --glass-dark: linear-gradient(
    111.02deg,
    rgba(18, 18, 18, 0.7) 3.86%,
    rgba(18, 18, 18, 0) 107.96%
  );
  --glass-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  --glass-purpler: linear-gradient(180deg, #933eff 0%, rgba(187, 133, 255, 0) 33.97%);
  --glass-red: linear-gradient(180deg, #c31432 3.12%, rgba(195, 20, 50, 0) 100%);
  --glass-pink-violet: linear-gradient(96.51deg, #cf3ad2 7.05%, #5936e4 93.87%);
  --business-purple-glass: linear-gradient(90deg, #2f0559 4.2%, #863ad2 52.87%, #b736e4 97.03%);
  --goal-green-glass: linear-gradient(97deg, #2f0559 7.05%, #5539c6 53.18%, #00ffb2 93.87%);
  --highlight-gray: #2e2e2e;
  --lighter-gray: #717171;
  --white: #fff;
  --white-10: #ffffff1a;
  --white-20: #ffffff33;
  --white-25: #ffffff40;
  --white-50: #ffffff80;
  --white-90: #ffffffe6;
  --ghost-white: #f2f2f7;
  --important-black: #151426;
  --black-dark: #1a2535;
  --black-darkest: #111a27;
  --flat-black: #333333;
  --black: #000;
  --black-25: #00000040;
  --black-50: #00000080;
  --black-90: #000000e6;
  --black-footer: #010101;
  --little-black: #292929;
  --red: #ff0000;
  --error-background: 20, 26, 32, 0.9;
  --notification-box-shadow: 0px 0px 16px #05499863;
  --green: #00dc9b;
  --yellow: #eeecb4;
  --dark-yellow: #edbd02;
  --border-gray: #eaeaea;
  --border-dark-gray: #acacac;
  --purple: #b53dc5;
  --blue-gem-gradient: linear-gradient(358.73deg, rgba(61, 36, 165, 0) 13.2%, #3d24a5 99.82%);
  --blue: #2c6ed0;
  --lochmara: #0073cb;
  --menu-background: #1d1d1d;
  --menu-btn: #333333;
  --menu-detail: #989898;
  --menu-subcategory: #777777;
  --menu-gradient: linear-gradient(180deg, #111111 0%, rgba(17, 17, 17, 0) 100%);
  --manage-form-input: #272727;
  --manage-form-placeholder: #aaaaaa;
  --lab-status-green: #22d895;
  --lab-status-yellow: #ffde29;
  --lab-status-red: #fd352e;
  --tango: #ef7d23;
  --highlighter: #ffc504;
  --highlighted: #f83333;
  --metric-gradient: radial-gradient(closest-side at 50% 50%, #215bc7 0%, #2113b100 100%) 0% 0%
    no-repeat padding-box;
  --paypal-gradient: linear-gradient(90deg, #234c8d 0%, #1c91ce 100%);
  --secondary-promo-purple-gradient: linear-gradient(90deg, #863ad2 3.58%, #5936e4 97.14%);
  --secondary-promo-purple-2: linear-gradient(45deg, #ef7d22 -100%, #863ad2 30%, #190da0),
    linear-gradient(90deg, transparent 1%, #3c1aef 80%);
  --cert-gray: #252525;
  --cert-gray-50: #25252580;
  --charcoal: #1e1e1e;
  --free-alt-bg: #f7f8fa;
  --off-white: #f7f8f9;
  --error: #ff0000;
  --detail: #acb2bf;
  --input-background: #111720;
  --input-border: #29285a;
  --cart-background: 20, 26, 32, 0.9;
  --cod-gray: #1c1c1c;
  --dove-gray: #666666;
  --silver-gray: #8b8b8b;
  --emperor-gray: #555555;
  --payment-border: #202245;
  --payment-border-selected: #3f4388;
  --payment-box-shadow: 0px 0px 16px #05499863;
  --checkbox-border: #30336a;
  --tundora-gray: #444444;
  --silver-chalice: #b1b1b1;
  --light-blue-gray: #afb0bf;
  --perfect-gray: #888888;
  --athens-gray: #e9e9ed;
  --dusty-gray: #e9e9ed;
  --positive-dark: #009d6f;
  --positive-light: #70ffd4;
  --positive-light-15: #00da9926;
  --positive-light-20: #00da9933;
  --cornflower-blue: #88a9ff;
  --silver-chalice-2: #aaaaaa;
  --mid-gray: #bbbcc7;
  --mid-gray-dark: #797b8c;
  --mid-gray-dark-25: #797b8c40;
  --dark-brighter: #111111;
  --aubergine: #221f20;
  --red-gradient: linear-gradient(
    90deg,
    #db4133 8310%,
    #de5936 11665.11%,
    #e27339 15935.25%,
    #e37c3a 18477%
  );
  --gray-lavender: #717071;
  --gray-nine: #999999;
  --gray-storm: #797b8c; // named by https://www.hexdictionary.com/color/797b8c
  --chetwode-blue: #a793f6; // named by https://www.hexdictionary.com/color/A793F6
  --chetwode-blue-50: #a793f680; // named by https://www.hexdictionary.com/color/A793F6
  --orange-gradient: linear-gradient(
    180.18deg,
    #db4133 -5.52%,
    #de5936 36.62%,
    #e27339 90.25%,
    #e37c3a 122.17%
  );
  --oranger-gradient: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  --orangest-horizontal-gradient: linear-gradient(
    90deg,
    #db4133 0%,
    #de5935 33%,
    #e17238 75%,
    #e37c3a 100%
  );
  --orangest-vertical-gradient: linear-gradient(
    180.18deg,
    #db4133 -5.52%,
    #de5935 36.62%,
    #e17238 90.25%,
    #e37c3a 122.17%
  );
  --purple-gradient: linear-gradient(96.51deg, #863ad2 7.05%, #5936e4 93.87%);
  --purple-horizontal-gradient: linear-gradient(270deg, #803995 0.01%, #ea2426 100.01%);
  --purple-vertical-gradient: linear-gradient(0deg, #803995 0.01%, #ea2426 100.01%);
  --purple-pale: #c9bbff;
  --perfume: #dbbeff; // named by https://www.hexdictionary.com/color/DBBEFF
  --dark-blue: #190da0;
  --caribbean-green: #49e0b3; // named by https://www.hexdictionary.com/color/49e0b3
  --carnation: #ff5e5e; // named by https://www.hexdictionary.com/color/ff5e5e
  --carnation-sonar: #fe6d6d;
  --butterfly-bush: #5b4b9b; //https://www.hexdictionary.com/color/5B4B9B
  --secondary-purple: #5b4b9b; //https://www.hexdictionary.com/color/5B4B9B
  --shark-gray: #2a2a2f; //https://www.hexdictionary.com/color/2a2a2f
  --pale-yellow: #fffec9;
  --light-yellow: #fce57f;
  --performance-light: #f8b369;
  --apricot-peach: #f8cead;
  --concrete: #f2f2f2;
  --melrose: #c4b7f9;
  --melrose-quartz: #ded6ff;
  --melrose-quartz-80: #d6ccffcc;
  --white-light-box-shadow: 0px 4px 40px rgba(255, 255, 255, 0.4);
  --white-medium-box-shadow: 0px 0px 19px rgba(255, 255, 255, 0.26);
  --gray-light-box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  --gray-light-box-shadow-filter: drop-shadow(0px 1px 2px rgba(55, 65, 81, 0.08));
  --carnation-box-shadow: 0px 0px 0px 1px rgba(255, 94, 94, 0.3), inset 0px 0px 0px 1px #ff5e5e;
  --purple-dark-box-shadow: 0px 0px 0px 2px #5b4b9b, inset 0px 0px 0px 1px #a793f6;
  --purple-light: #a793f6;
  --purple-light-box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
  --positive-light-shadow: 0px 0px 10px var(--positive-light);
  --violet-light: #d7cdff;
  --ice-cold: #b5ffe9; //https://www.hexdictionary.com/color/b5ffe9
  --fog: #d3c9fa; //https://www.hexdictionary.com/color/D3C9FA
  --larea-networking: #fad740;
  --larea-cloud: #88a9ff;
  --larea-data-science: #66c19a;
  --larea-cyber-security: #bb85ff;
  --larea-it-essentials: #f647c0;
  --larea-development: #d73f47;
  --content-lab-standard: #f8cead;
  --content-lab-skill-dive: #f8cead;
  --content-video: #f3df9c;
  --content-quiz: #c1d8f8;
  --content-exercise: #d5cfcd;
  --content-slide: #b2d4d1;
  --purple-dark: #7965c6;
  --egg-orange: #f28815;
  --orange-bg: #f8b3690d;
  --important-yellow-gradient: linear-gradient(90deg, #f7c64c 0%, #f90 100%);
  --sonar-gradient-1: linear-gradient(96.51deg, #5539c6 7.05%, #3583be 93.87%);
  --sonar-gradient-2: linear-gradient(96.51deg, #3583be 7.05%, #15c9b8 93.87%);
  --sonar-gradient-3: linear-gradient(96.51deg, #15c9b8 7.05%, #00ffb2 93.87%);
  --sonar-radial-gradient-red: linear-gradient(
    249.58deg,
    rgba(95, 0, 244, 0.66) 22.25%,
    #ea2c5a 36.38%,
    #ff0034 51.69%
  );
  --sonar-radial-gradient-blue: linear-gradient(
    249.58deg,
    rgba(95, 0, 244, 0.66) 22.25%,
    #355ab4 36.38%,
    #2b6bc8 51.69%
  );
  --sonar-radial-gradient-yellow: linear-gradient(
    249.58deg,
    rgba(95, 0, 244, 0.66) 22.25%,
    #ff4c00 36.38%,
    #ff9900 51.69%
  );
  --sonar-radial-gradient-cisco: linear-gradient(
    285deg,
    rgba(95.03, 0, 244.37, 0.66) 0%,
    #00bceb 100%
  );
  --sonar-gradient-level-novice: linear-gradient(114.49deg, #b0a3e4 21.83%, #555dc7 158.43%);
  --sonar-gradient-level-professional: linear-gradient(114.49deg, #9ecce0 21.83%, #31b4be 158.43%);
  --sonar-gradient-level-advanced: linear-gradient(114.49deg, #95eddf 21.83%, #8dd6e4 158.43%);
  --sonar-gradient-level-locked: linear-gradient(114.49deg, #9e9e9e 21.83%, #3f3f3f 158.43%);
  --sonar-gradient-level-coming-soon: linear-gradient(114.49deg, #9e9e9e 21.83%, #3f3f3f 158.43%);
  --sonar-gradient-border-level-novice: linear-gradient(114.49deg, #5639c6 21.83%, #328cbe 158.43%);
  --sonar-gradient-border-level-professional: linear-gradient(90deg, #3090be 0%, #14d2b7 100%);
  --sonar-gradient-border-level-advanced: linear-gradient(90deg, #13d5b7 0%, #01ffb3 100%);
  --sonar-gradient-border-assessment-status-pill: linear-gradient(
    352deg,
    rgba(187, 165, 214, 1) 0%,
    rgba(88, 88, 95, 1) 16%,
    rgba(88, 88, 95, 1) 54%,
    rgba(172, 172, 176, 1) 100%
  );
  --mid-gray-translucid-gradient-four: linear-gradient(
    104.99deg,
    rgba(187, 188, 199, 0.4) 0%,
    rgba(187, 188, 199, 0) 100%
  );
  --dodger-lighter-blue: #7da5fb;
  --orange-horizontal-gradient: linear-gradient(
      90deg,
      rgba(239, 157, 34, 0) 56.15%,
      #efb622 108.83%
    ),
    #d95b00;
  --orange-light: #ff9000;
  --red-light: #f02222;
  --icon-default: #57575c;
  --icon-aside: #3a3a5d;
  --icon-aside-mobile: #bb85ff;
  --grey-lighter: #f2f2f7;
  --grey-light: #d0d2db;
  --grey-medium: #a9a7bc;
  --grey-dark: #aeaeb2;
  --grey-blue-light: #afb0bf;
  --grey-day: #888e97;
  --performance-positive: #49e0b3;
  --lilac: #a793f6;
  --lilac-dark: #7965c6;
  --lilac-dark-60: #7965c699;
  --lilac-light: #c4b7f9;
  --lilac-lighter: #d3c9fa;
  --secondary-promo-purple: #863ad2;
  --purple-gradient-teams: linear-gradient(
    41deg,
    rgba(197, 86, 118, 1) 0%,
    rgba(124, 56, 213, 1) 25%,
    rgba(124, 56, 213, 1) 59%,
    rgba(64, 23, 249, 1) 99%
  );
  --pink-lighter: #fec3cb;
}
